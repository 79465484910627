import { createRoot } from "react-dom/client"
import React, { Suspense } from "react"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./i18next"
import { persistor, store } from "./store/configureStore"
import { Provider } from "react-redux"
import { registerLicense } from "@syncfusion/ej2-base"
import { PersistGate } from "redux-persist/integration/react"

registerLicense("Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlfdHRRR2leV0Z1W0o=")
const root = createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor} loading={null}>
				<Suspense fallback={<div></div>}>
					<App />
				</Suspense>
			</PersistGate>
		</Provider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
